<template>
  <base-button type="primary" @click="exportOriginPicture">
    批量导出原图
  </base-button>
</template>

<script>
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { getCustomProductDesignImageByFilter } from '@/api/product/knifeProductApi'
import BaseButton from '@/components/base/baseButton'
import { saveAs } from 'file-saver'
import { formatDate } from 'element-ui/src/utils/date-util'
import { isUndefined } from 'lodash'

export default {
  components: { BaseButton },
  props: {
    sup_this: {
      required: true
    }
  },

  methods: {
    async exportOriginPicture() {
      const params = {
        ...this.sup_this.prevParams || {},
        ...REQUEST_ALL_DATA
      }
      const isFilter = Object.keys(this.sup_this.prevParams || {}).filter(prop => {
        const val = $GET(this.sup_this.prevParams, prop, undefined)
        return !isUndefined(val)
      }).length
      if (!isFilter) return this.$message.warning('至少需要选择一个筛选项，才能执行此操作')
      const res = await awaitResolveDetailLoading(getCustomProductDesignImageByFilter(params))
      if (!res) return
      const txt = new Blob(
        [res.join('\n')],
        { type: 'text/plain;charset=utf-8' }
      )
      saveAs(txt, `${formatDate(new Date(), 'yyyyMMdd')}原图导出.txt`)
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
