import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function list(params) {
  return request({
    url: '/api/product/product/',
    method: 'get',
    params
  })
}

export function add(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/product/product/',
    method: 'post',
    data
  })
}

export function read(data) {
  return request({
    url: `/api/product/product/${data}/`,
    method: 'get',
    data
  })
}

export function edit(id, data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/product/product/' + id + '/',
    method: 'patch',
    data
  })
}

export function del(id, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/api/product/product/' + id + '/',
    method: 'delete'
  })
}

export function knifeList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/customProduct/list',
    method: 'post',
    data
  })
}

export function testAlgo(data) {
  return request({
    url: '/externaladmin/productService/customSpecificProduct/createOrUpdate',
    method: 'post',
    data
  })
}

export function testAlgoShowImg(data) {
  return request({
    url: '/externaladmin/productService/customShowImage/createOrUpdate',
    method: 'post',
    data
  })
}

//批量下载原图
export function getCustomProductDesignImageByFilter(data) {
  return request({
    url: '/externaladmin/productService/getCustomProductDesignImageByFilter',
    method: 'post',
    data
  })
}
